import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import FileUpload from '../FileUpload/FileUpload';
import { Bars } from 'react-loader-spinner';
import { useParams, Link ,useNavigate} from 'react-router-dom'
import { useSelector } from 'react-redux/es/hooks/useSelector';

function OpportunityData() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const title = "Project Detail";
    const { id } = useParams();
    const [opportunity, setOpportunity] = useState({});
    const [servicesInterested, setService] = useState([]);
    const [opportunityName, setOpportunityName] = useState('');
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [opportunityAmount, setOpportunityAmount] = useState('');
    const [opportunityStatus, setOpportunityStatus] = useState('');
    const [notes, setNotes] = useState();
    const [storage, setStorage] = useState();
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFollowModal, setShowFollowModal] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [Image, setImage] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [TeamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;

    const refreshState = () => {
        getOppurtunityDetail();
      };

    useEffect(() => {
        dispatch(pageTitle(title));
        getOppurtunityDetail();
        getNotes();
    }, [])

    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [removeTeamMembers, setRemoveTeamMembers] = useState([]);
    const handleSelectTeamMembers = (selectedMembers) => {
        setSelectedTeamMembers(selectedMembers);
    };

        const formatDateTime = (dateString) => {
            const date = new Date(dateString);
            const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
            const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
            return `${formattedDate} ${formattedTime}`;
        };

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setService([...servicesInterested, value]);

        } else {
            setService(servicesInterested.filter(item => item !== value));
        }
    };
 

    function changeHandler(e) {
        if (e.target.name === 'image') {
            setImage({ [e.target.name]: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) });
        }
    }
    const [editorData, setEditorData] = useState("");
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
        console.log(data);
    }


    const getOppurtunityDetail = () => {
        setLoading(true);

        API.get('admin/detail/' + id + '')
            .then((response) => {
                if (response.status === 200) {
                    console.log(response.data)
                    setLoading(false);
                    setOpportunity(response.data.data);
                    setService(response.data.service);
                    setTeamMembers(response.data.matchingUsers)
                    setOpportunityName(response.data.data.opportunityName);
                    setContactName(response.data.data.contactName);
                    setPhoneNumber(response.data.data.phoneNumber);
                    setEmail(response.data.data.email);
                    setOpportunityAmount(response.data.data.opportunityAmount);
                    setOpportunityStatus(response.data.data.opportunityStatus); 

                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };


    const getNotes = () => {
        setLoading(true);

        API.get('admin/notes/' + id + '')
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    setNotes(response.data.data);
                    setStorage(response.data.storage);
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    }
    const getFile = (e) => {
        e.preventDefault();
        const keyName = e.target.value;
        setLoading(true);
        API.get('admin/file/' + keyName + '')
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.setAttribute('download', keyName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(response.data);
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'opportunityName':
                setOpportunityName(value);
                break;
            case 'contactName':
                setContactName(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'opportunityAmount':
                setOpportunityAmount(value);
                break;
            case 'opportunityStatus':
                setOpportunityStatus(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const userInvited = selectedTeamMembers;
        const removeUsers = removeTeamMembers;
        const updatedDetails = { opportunityName, opportunityAmount, opportunityStatus, Image, editorData, token, servicesInterested, userInvited, removeUsers };
        setLoading(true);

        API.post('admin/updateDetails/' + id + '/'+ ActiveuserId + '', updatedDetails)
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    setEditorData('');
                    getNotes();
                    getOppurtunityDetail();
                    toast.success("Details Updated ", {
                        position: "top-center"
                    });
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
            });
    };

    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleShowModalFollow = () => {
        setShowFollowModal(true);
    };

    const handleShowLogModal = () => {
        setShowLogModal(true);
    };

    const handleShowModalDelete = () => {
        setShowDeleteModal(true);
      };

    const handleRemove = async (memberId) => {
        try {
             // Filter out the member with the specified email
             const updatedTeamMembers = TeamMembers.filter(member => member.email !== memberId);
             setTeamMembers(updatedTeamMembers);
            setRemoveTeamMembers(prevMembers => [...prevMembers, memberId]);
        } catch (error) {
            console.error("Error removing member:", error);
        }
    };

        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(date.getDate()).padStart(2, '0');
            const year = date.getFullYear();
            return `${month}/${day}/${year}`;
        };

    return (
        <div className="main-dashboard-admin">
             {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
            <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>

            <div className="addNewOppFields oppount-data">
                <div className="fieldsBlock">
                    <div className="field ">
                        <label htmlFor="">Opportunity Company Name<span className='' style={{ color: 'red' }}>*</span></label>
                        <div className="selectDropdown">
                            <select name="companyName" id="types">
                                <option>{opportunity.companyName}</option>
                            </select>
                            

                        </div>
                    </div>
                    <div className="field opportunitiesName">
                        <label htmlFor="">Opportunity Name</label>
                        <input
                        disabled
                            type="text"
                            placeholder=""
                            name='opportunityName'
                            value={opportunityName}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="field">
                        <label htmlFor="">Opportunity Status<span className='' style={{ color: 'red' }}>*</span></label>
                        <input
                        disabled
                            type="text"
                            placeholder=""
                            name='opportunityStatus'
                            value={opportunityStatus}
                            
                        />
                    </div>

                
                    <div className="field">
                        <label htmlFor="">Opportunity Amount</label>
                        <div className='dollarWrap'>
                            <div className='dollarSign'>$</div>
                            <input
                            disabled
                                type="number"
                                placeholder=""
                                name='opportunityAmount'
                                value={opportunityAmount}
                                onChange={handleChange}
                            />
                        </div>
                    </div>

                    <div className="field checkBoxOptions ">
                        <label htmlFor="">Services Interested In: <p><span><svg xmlns="http://www.w3.org/2000/svg"
                            width="14.718" height="16.82" viewBox="0 0 14.718 16.82">
                            <path id="Icon_awesome-calendar-check" data-name="Icon awesome-calendar-check"
                                d="M14.324,5.256H.394A.394.394,0,0,1,0,4.862V3.679A1.577,1.577,0,0,1,1.577,2.1H3.154V.394A.394.394,0,0,1,3.548,0H4.862a.394.394,0,0,1,.394.394V2.1H9.461V.394A.394.394,0,0,1,9.856,0H11.17a.394.394,0,0,1,.394.394V2.1h1.577a1.577,1.577,0,0,1,1.577,1.577V4.862A.394.394,0,0,1,14.324,5.256ZM.394,6.308H14.324a.394.394,0,0,1,.394.394v8.542a1.577,1.577,0,0,1-1.577,1.577H1.577A1.577,1.577,0,0,1,0,15.243V6.7A.394.394,0,0,1,.394,6.308ZM11.344,9.46l-.925-.933a.394.394,0,0,0-.558,0L6.377,11.98,4.867,10.457a.394.394,0,0,0-.558,0l-.933.925a.394.394,0,0,0,0,.558l2.714,2.736a.394.394,0,0,0,.558,0l4.7-4.659a.394.394,0,0,0,0-.558Z"
                                fill="#083d78" />
                        </svg>

                            Follow Up Date:</span>{formatDate(opportunity.followUpDate)}</p></label>
                            <div className="ServicesInterested">
                            <div className="field">
                                <input
                                    disabled
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Development'
                                    checked={servicesInterested.includes('Development')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Development</span>
                            </div>
                            <div className="field">
                                <input
                                    disabled
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Design'
                                    checked={servicesInterested.includes('Design')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Design</span>
                            </div>
                            <div className="field">
                                <input
                                    disabled    
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Automation'
                                    checked={servicesInterested.includes('Automation')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Automation</span>
                            </div>
                            <div className="field">
                                <input
                                    disabled
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='SEO'
                                    checked={servicesInterested.includes('SEO')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>SEO</span>
                            </div>
                            <div className="field">
                                <input
                                    disabled
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='PPC'
                                    checked={servicesInterested.includes('PPC')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>PPC</span>
                            </div>
                            <div className="field">
                                <input
                                    disabled
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Social Media'
                                    checked={servicesInterested.includes('Social Media')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Social Media</span>
                            </div>
                            <div className="field">
                                <input
                                    disabled
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Email Marketing Reputation'
                                    checked={servicesInterested.includes('Email Marketing Reputation')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Email Marketing Reputation</span>
                            </div>
                            <div className="field">
                                <input
                                    disabled
                                    type="checkbox"
                                    name='servicesInterested'
                                    className='custom-checkbox'
                                    value='Other'
                                    checked={servicesInterested.includes('Other')}
                                    onChange={handleCheckboxChange}
                                />
                                <span>Other</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="postedNotesBlock">
                    <h4>Posted Notes</h4>
                    {notes && notes.map((items) => (
                        <div className="commentedNotes">
                            <div className="">
                                <div className="personImage">
                                    {/* <img src="" alt="" /> */}
                                </div>
                            </div>
                            <div className="commentBox">
                                <h4>{items.user.firstName} {items.user.lastName}</h4>
                                <p> <div dangerouslySetInnerHTML={{ __html: items.notes }} /></p>
                                {items.media && JSON.parse(items.media).map((file, index) => (
                                    <div>
                                        <img src={`${items.mediaPath}/${items.project.opportunityName}/${file}`} alt="Media" width="500px" height="300px" />
                                        {file.type === 'document' && <a href target="_blank" rel="noopener noreferrer">Download Document</a>}
                                    </div>
                                ))}
                                    <span>{formatDateTime(items.createdAt)}</span>
                                </div>
                        </div>
                    ))}
                    {
                        storage && storage.map((items) => (
                            <button type='button' name="storage_key" value={items.storageKey} onClick={getFile}>
                                {items.storageKey}
                            </button>
                        ))
                    }
                </div>
               
                <ToastContainer />
            </div>
        </div>
    )
}

export default OpportunityData;