import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../features/actions/pageTitleActions';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import API from '../AxiosConfig';

function SearchDetails() {
    const location = useLocation();
    const { searchData, query } = location.state || { searchData: [], query: '' }; // Default to empty array and empty string

    function stripHtmlTags(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    }
    const searchedComments = searchData.comments;
    return (
        <div className="main-dashboard-admin">
            <div className='search-main-outer'>
                <div className='search-heading'>
                    <h2>Searching for <span>{query}</span></h2>
                </div>
                <div className='search-outer-sec'>
                    {searchedComments?.length > 0 ? (
                        searchedComments?.map((item, index) => (
                            <div key={index} className="search-data">
                                <div className='search-data-inner'>
                                    <div className='search-date'>
                                        <span>{new Date(item?.createdAt).toLocaleString()}</span>
                                    </div>
                                    <div className='progress-client-details'>
                                        <div className="progress-client">
                                            <img src='/assets/images/progress-img.png' alt="Progress" />
                                        </div>
                                        <div className='progress-date'>
                                        
                                        <a href={`clients/client-detail/project-detail/discussions/discussion-detail/${item.discussion?.id}`}>Re: {item.discussion?.title}</a>
                                        <div className='text-pro'>
                                        <p>{ item.user?.firstName } - {stripHtmlTags(item?.comment)}</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <p>No search results found.</p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SearchDetails;
