import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function DeleteTask({ showDeleteModal, setShowDeleteModal, taskId }) {  // Renamed to start with uppercase
    const navigate = useNavigate();

    const handleClose = () => {
        setShowDeleteModal(false);
    };

    const handleDelete = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await API.delete(`/board/delete-task/${taskId}`, {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                toast.success('Task deleted successfully', {
                    position: toast.POSITION.TOP_CENTER,
                });
                navigate(-1);  // Navigates back to the previous page
            } else {
                toast.error('Error while deleting task', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            console.error('Error deleting task:', error);
            toast.error('Error while deleting task', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    return (
        <>
            <Modal centered show={showDeleteModal} onHide={handleClose} className='add-team-modal'>
                <Modal.Header closeButton>
                    <h4 className="modal-title">
                        Delete Task
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="calender-box">
                        <div className="question-icon">
                            {/* SVG Icon */}
                        </div>
                        <p>Are you sure you want to delete this task?</p>
                    </div>
                    <div className="form-btn-rows">
                        <button type="button" onClick={handleDelete} className="btn global-icon-btn orange-btn">Yes</button>
                        <button type="button" onClick={handleClose} className="btn global-icon-btn">No</button>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DeleteTask;  // Ensure export uses the updated component name
