import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../AxiosConfig';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function CreateTask({ showBoard, setShowBoard, columnId, boardId, onTaskCreated }) {
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDescription, setTaskDescription] = useState('');
  const [isIssue, setIsIssue] = useState(false); 
  const userData = useSelector(state => state.auth.user);

  const handleClose = () => {
    setShowBoard(false);
  };

  const Createdby = `${userData?.user?.firstName} ${userData?.user?.lastName} (${userData?.user?.email})`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await API.post('/board/columnsTasks', {
        taskTitle: taskTitle,
        taskDescription: taskDescription,
        columnId: columnId,
        boardId: boardId,
        issue: isIssue,
        userId: Createdby 
      });
      toast.success('Task/Issue created successfully!');
      setShowBoard(false);
      onTaskCreated();
    } catch (error) {
      toast.error('Error creating task');
      console.error('Error creating task:', error);
    }
  };

  return (
    <>
      <Modal centered show={showBoard} onHide={handleClose} className='add-team-modal add-task-module'>
        <Modal.Header closeButton>
          <h4 className="modal-title">Create Task/Issue for this project</h4>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="task-title" className="form-label">Task/Issue Title</label>
              <input
                type="text"
                id="task-title"
                value={taskTitle}
                onChange={(e) => setTaskTitle(e.target.value)}
                placeholder="Enter task title"
                className="form-control"
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="task-description" className="form-label">Task/Issue Description</label>
              <textarea
                id="task-description"
                value={taskDescription}
                onChange={(e) => setTaskDescription(e.target.value)}
                placeholder="Enter task description"
                className="form-control"
                required
              ></textarea>
            </div>
            <div className="mb-3 checkbox-task">
              <input
                type="checkbox"
                id="is-issue"
                checked={isIssue}
                onChange={() => setIsIssue(!isIssue)}
                className="custom-checkbox"
              />
              <label htmlFor="is-issue" className="form-label">Urgent Task</label>
            </div>
            <div className="form-btn-rows">
              <button type="submit" className="btn global-icon-btn orange-btn">Submit</button>
              <button type="button" onClick={handleClose} className="btn global-icon-btn">Cancel</button>
            </div>
          </form>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CreateTask;
