import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';


function UnArchiveClientModal({ showUnModal, setShowUnModal, id, company }) {
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);
  const handleClose = () => {
    clearTimeout(timer); // Clear the timer if modal is closed manually
    setShowUnModal(false);
  };

  const UnarchiveClient = async () => {
    try {
      const response = await API.post(`/admin/unarchiveClient/${id}`, {
        userId: userData.user.id
      });

      if (response.status === 200) {
        console.log(response.data.message);
        toast.success(response.data.message);
        handleClose();
        const timerId = setTimeout(() => {
          navigate(`/archived-clients`);
        }, 3000);
        setTimer(timerId);
      } else {
        toast.error('Details not saved');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Error saving data');
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer); // Cleanup timer on component unmount
    };
  }, []);

  return (
    <Modal centered show={showUnModal} onHide={handleClose} className='add-team-modal'>
      <Modal.Header closeButton>
        <h4 className="modal-title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="19.2"
            viewBox="0 0 24 19.2"
          >
            {/* SVG Path */}
          </svg>
          Unarchive Client
        </h4>
      </Modal.Header>
      <Modal.Body>
        <div className="calender-box">
          {/* Your content here */}
          <p>Are you sure you want to Unarchive this client?</p>
        </div>
        <div className="form-btn-rows">
          <button type="button" onClick={UnarchiveClient} className="btn global-icon-btn orange-btn">Yes</button>
          <button type="button" onClick={handleClose} className="btn global-icon-btn">No</button>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Modal.Body>
    </Modal>
  );
}

export default UnArchiveClientModal;
