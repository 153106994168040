import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import AdminDashboard from './dashboard/AdminDashboard'
import ClientDashboard from './dashboard/ClientDashboard'
import { UseSelector, useSelector } from 'react-redux/es/hooks/useSelector';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../features/actions/pageTitleActions';
import { setBreadcrumbs } from "../features/actions/pageBreadCrumps"; 

function Dashboard() {
  const title = "Dashboard";
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(pageTitle(title));
    dispatch(setBreadcrumbs([
      { label: "Home", path: "/", active: true },
  ]));
  },[])
  return (
    <>
     <div className="main-dashboard-admin">Dashboard</div>
    </>
  )
}

export default Dashboard