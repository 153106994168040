import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link ,useNavigate ,useLocation} from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import TimeAgo from './TimeAgo';
import { Tooltip } from 'react-tooltip';
import { Bars } from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import AssignChecklistModal from './Modals/AssignChecklistModal';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 
import ArchiveClientModal from './Modals/archiveClientModal';
import UnArchieveClientModal from './Modals/unarchiveClientModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MultiSelect } from "react-multi-select-component";

function ClientDetail() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [title, setTitle] = useState(' ');
    const [formData, setFormData] = useState({ id: '', search: '', projectType: '', sortBy: '' });
    const [value, setValue] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortByStars, setSortByStars] = useState('');
    const [clientDetail, setClientDetail] = useState({ content: '' });
    const [show, setShow] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [content, setContent] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [stars,setStars] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const [users, setUsers] = useState([]); // State to store users for MultiSelect
    const [selectedUsers, setSelectedUsers] = useState([]); // State for selected users
    let projectId = '';
    const location = useLocation();
    const pathnames = location.pathname.split("/").filter((x) => x);
    let breadcrumbPath = ""
    const getClientDetail = async () => {
        try {
            setLoading(true);
            const response = await API.get(`/admin/clientDetail/${id}`);
            const clientName = response.data?.data?.clientDetails?.name;
            dispatch(pageTitle(clientName));
            setStars(response.data.stars);
            console.log("response.data.data.clientDetails", response.data.data.clientDetails);
            setClientDetail(response.data.data.clientDetails);
            setContent(response.data?.data?.clientDetails?.content);
            dispatch(setBreadcrumbs([
                { label: "Home", path: "/" },
                { label: "Clients", path: "/clients" },
                { label: response.data?.data?.clientDetails?.name, path: `/clients/client-detail/${response.data?.data?.clientDetails?.id}`, active: true }
            ]));

        } catch (error) {
            setErrorMessage('Failed to load client details. Please try again later.');
            console.error('Error fetching client details:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleUserSelect = async (selectedUsers) => {
        setSelectedUsers(selectedUsers);
      
        // Call the API to save selected users
        const clientId = id; // Get the current client ID from your state or props
        console.log("clientId", clientId);
        try {
            const response = await API.post('/admin/assign-users', 
                {
                  clientId,
                  selectedUsers: selectedUsers.map(u => u.value),
                },
                {
                  headers: { 'Content-Type': 'application/json' }
                }
              );
              
      
          if (response.status == 200) {
            toast.success('Users assigned successfully!');
          } else {
            toast.error('Failed to assign users.');
          }
        } catch (error) {
          console.error('Error assigning users:', error);
          toast.error('Failed to assign users.');
        }
      };

      
    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const [showModal, setShowModal] = useState(false);
    const [showUnModal, setShowUnModal] = useState(false);
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleShowUnModal = () => {
        setShowUnModal(true);
    };


    const handleSearch = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    const handleSortChange = (e) => {
        const { name, value } = e.target;
        if (name === 'sortby') {
            setSortBy(value);
        } else if (name === 'sortbystars') {
            setSortByStars(value);
        }
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleContentChange = (event) => {
        setContent(event.target.value);
    };
    
    const handleClose = () => {
        setShow(false);
    };

    const handleButtonClick = () => {
        API.put('/admin/company-names',
            {
                content: content,
                id: `${id}`,
                user: userData.user.id
            })
            .then(response => {
                console.log(response.data);
                getClientDetail();
                setShow(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const sortProjects = (filterBySearch) => {
        switch (sortBy) {
            case "Alphabetical":
                return filterBySearch.sort((a, b) =>
                    a.opportunityName.localeCompare(b.opportunityName)
                );
            case "Star":
                return filterBySearch.sort((a, b) =>
                    b.star.id - a.star.id
                );
            default:
                return filterBySearch;
        }
    };
    

    const filterBySearch = clientDetail?.projects?.filter(item => {
        if (!value) {
            return item;
        } else {
            if (item?.opportunityName.toLowerCase().includes(value.toLowerCase())) {
                return item;
            }
        }
    });
    const filteredAndSortedData = sortProjects(filterBySearch);
    const handleStar = (star,projectId) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        API.get(`/admin/updateProjectStar/${star?.id}/${projectId}`,
            {headers : {Authorization:`Bearer ${token}`}}
        )
        .then((response) => {
            setLoading(false);
            getClientDetail()
        }).catch((e) => {
            setLoading(false);
            console.log(e);
        });
    }

     // Fetch users for MultiSelect
     const fetchUsers = () => {
        API.get('/admin/users') // Adjust the API endpoint based on your setup
          .then((response) => {
            if (response.status === 200) {
              const userOptions = response.data.map(user => ({
                label: user.firstName, // Adjust as needed (e.g., 'user.firstName' + 'user.lastName')
                value: user.id
              }));
              setUsers(userOptions);
            }
          })
          .catch((error) => {
            console.error(error);
            toast.error('Error fetching users', {
              position: toast.POSITION.TOP_CENTER,
            });
          });
      };
      const fetchAssignedUsers = async (clientId) => {
        try {
            const response = await API.get(`/admin/assigned-users/${clientId}`); // Adjust the endpoint as needed
            console.log("response", response);
            if (response.status === 200) {
                // Extract userIds from the response data
                const assignedUserIds = response.data.map(user => user.userId); // Change this line to access userId
    
                // Update selected users after fetching the user list
                setSelectedUsers(users.filter(user => assignedUserIds.includes(user.value)));
            }
        } catch (error) {
            console.error('Error fetching assigned users:', error);
            toast.error('Error fetching assigned users', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };
    
    useEffect(() => {
        fetchUsers(); // Fetch all users
        getClientDetail();

    }, []); // This will run once when the component mounts
    
    useEffect(() => {
        if (id) { // Ensure that id is available
            fetchAssignedUsers(id); // Fetch assigned users for the specific client
        }
    }, [id, users]); // Run whenever 'id' or 'users' changes
    
    
    // useEffect(() => {
    //     fetchUsers(); // Fetch all users
    //     if (id) { // Ensure that id is available
    //         fetchAssignedUsers(id); // Fetch assigned users for the specific client
    //     }
    // }, [id]); // Run whenever 'id' changes
    
    

    // useEffect(() => {
    //     setFormData(prevFormData => ({ ...prevFormData, id: id }));
    //     getClientDetail();
    //     fetchUsers(); // Call function to fetch users
    // }, [id, dispatch, title]);


    return (
        <div className="main-dashboard-admin">
            {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <div className='cient-announcement team-header-info'>
            {clientDetail?.content && (
                    <div className='announcement-bar'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <path id="Icon_material-speaker-notes" data-name="Icon material-speaker-notes" d="M17.4,3H4.6A1.6,1.6,0,0,0,3.008,4.6L3,19l3.2-3.2H17.4A1.6,1.6,0,0,0,19,14.2V4.6A1.6,1.6,0,0,0,17.4,3ZM7.8,12.6H6.2V11H7.8Zm0-2.4H6.2V8.6H7.8Zm0-2.4H6.2V6.2H7.8Zm5.6,4.8h-4V11h4Zm2.4-2.4H9.4V8.6h6.4Zm0-2.4H9.4V6.2h6.4Z" transform="translate(-3 -3)" />
                        </svg>
                        <span>
                            {clientDetail.content}
                        </span>
                    </div>
                )}
            <div className='sr-cstm-padding'>
            

            <div className='sr-cstm-combo'>
            <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>
                    <div className='client-det-btns'>
                        <Link to={`/clients/client-detail/${id}`}>
                            <button className='global-icon-btn blue-btn' >Projects</button>
                        </Link>
                        <Link to={`/clients/archive-client-detail/${id}`}>
                                <button className='global-icon-btn orange-btn' >Archived Projects</button>
                            </Link>
                        {userData?.user?.roleId === 1 ? (
                        <>
                            <button className='global-icon-btn orange-btn' onClick={()=>{setShow(true)}} >Announcement Bar</button>
                            <Link to={`/clients/add-new-Project/${id}`}>
                            <button className='global-icon-btn orange-btn' >Add New Project</button>
                        </Link>
                        {clientDetail?.Archived ? (
                            <button className="global-icon-btn orange-btn" onClick={handleShowUnModal}>
                                Unarchive Client
                            </button>
                            ) : (
                                <button className="global-icon-btn orange-btn" onClick={handleShowModal}>Archive Client</button> 
                            )}
                        </>
                        ) : '' }

                       </div>
                        </div>
                <div className='Client-Detail'>
                    <div className='client-wrap-ot'>
                        {/* <div className='row-client-data'>
                            <div className='user-icon'>
                                <div className='fab-icon'><i className="fas fa-user fa-fw"></i></div>
                                <p>{clientDetail?.name}</p>
                            </div>
                        </div> */}
                         <div className='row-client-data'>
                            <div className='user-icon'>
                                <div className='fab-icon'><i className="fas fa-user fa-fw"></i></div>
                                <p>{clientDetail?.clientName}</p>
                            </div>
                        </div>
                        
                        <div className='row-client-data'>
                            <div className='user-icon'>
                                <div className='fab-icon'><i className="fas fa-envelope-open fa-fw"></i></div>
                                <a href={`mailto:${clientDetail?.email}`}>
                                    {Object.keys(clientDetail).length > 0 ? clientDetail?.email : ''}
                                </a>
                            </div>
                        </div>

                        <div className='row-client-data'>
                            <div className='user-icon'>
                                <div className='fab-icon'><i className="fas fa-phone fa-fw"></i></div>
                                <p>{clientDetail?.phoneNumber}</p>
                            </div>
                        </div>
                        
                    </div>
                    {/* <div className="search-container">
                        <input type="text" placeholder="Search here" name="search" onChange={handleSearch} />
                        <button type="submit"><i className="fa fa-search"></i></button>
                    </div> */}
                </div>

                </div>
            </div>
            <div className='member-det-outer'>
                <div className="checklist-header-wrap team-wrap-details">
                    <div className="selectValues">
                        <div className="fields">
                            <label>Sort By:</label>
                            <div className="selectDropdown">
                            <select name="sortby" id="Checklists" onChange={handleSortChange}>
                                <option value=''>Default</option>
                                <option value='Alphabetical'>Alphabetical</option>
                                <option value='Star'>Star</option> 
                            </select>
                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                                        <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <MultiSelect
    options={users} // Use the users fetched from the API
    value={selectedUsers} // The selected users' state
    onChange={handleUserSelect} // Call handleUserSelect when users are selected
    labelledBy="Select Users"
/>
                    </div>
                    <div className="Toastify"></div>
                </div>
                <div className='member-card-det'>
                    {
                        filteredAndSortedData?.length > 0 ?
                            filteredAndSortedData?.map(project =>
                                <div className="card-det">
                                    <div className='client-info'>
                                        <Link to={`/clients/client-detail/project-detail/${project.id}`}>
                                            <div className='client-pro'>
                                                <span>{capitalize(project?.opportunityName)}</span>
                                            </div>
                                        </Link>
                                        <a id={`clickable${project?.id}`}>
                                            <div className='client-review '>
                                                <i className="fas fa-star" style={{color:project?.star ? project?.star?.name: ''}}></i>
                                            </div>
                                        </a>
                                        {userData?.user?.roleId === 1 ? (

                                        <Tooltip className='sr-vs-cstms' anchorSelect={`#clickable${project?.id}`} clickable >
                                            {
                                                stars.length>0?
                                                 stars.map((star) =>  
                                                    <button id={`description${star?.id}`} onClick={()=>handleStar(star,project?.id)}><i class="fas fa-star" style={{color: star?.name}}></i></button>
                                                 )
                                                :''
                                            }
                                        </Tooltip>
                                        ) : ''}
                                    </div>
                                    <div className='client-avater'>
                                        <div className='client-avatar'>
                                            {
                                                (Object.keys(project?.lastestComment).length > 0 || Object.keys(project?.latestDiscussion).length > 0) ? (
                                                    <>
                                                        <img src="/assets/images/client-img.png" alt="Client Avatar" />
                                                        <p>
                                                            {
                                                                Object.keys(project?.lastestComment).length > 0 ? 'Updated ' : ''
                                                            }
                                                            {
                                                                Object.keys(project?.lastestComment).length > 0 ?
                                                                    <TimeAgo updatedAt={project?.lastestComment.updatedAt} />
                                                                    :
                                                                    Object.keys(project.latestDiscussion).length > 0 ?
                                                                        <TimeAgo updatedAt={project.latestDiscussion.updatedAt} />
                                                                        : ''
                                                            }
                                                            {Object.keys(project?.lastestComment)?.length > 0 ? ' by ' + project?.lastestComment?.user?.firstName : Object.keys(project?.latestDiscussion)?.length > 0 ? ' by ' + project?.latestDiscussion?.addedByUser?.firstName : ""}
                                                        </p>
                                                    </>
                                                ) : (
                                                    <p>No discussion Added</p>
                                                )
                                            }
                                        </div>

                                        <div className='client-cmnt'>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.974" height="20.974" viewBox="0 0 20.974 20.974">
                                                        <path id="Icon_material-comment" data-name="Icon material-comment" d="M23.964,5.1A2.1,2.1,0,0,0,21.877,3H5.1A2.1,2.1,0,0,0,3,5.1V17.682a2.1,2.1,0,0,0,2.1,2.1H19.779l4.195,4.195ZM19.779,15.584H7.195v-2.1H19.779Zm0-3.146H7.195v-2.1H19.779Zm0-3.146H7.195v-2.1H19.779Z" transform="translate(-3 -3)" />
                                                    </svg>
                                                </div>
                                                <div className='client-dis'>
                                                    <strong>{project?.discussions?.length} </strong>
                                                    <p>Discussions</p>
                                                </div>
                                            </div>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                   
<svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="35px" height="35px" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/></svg>
                                                </div>
                                                <div className='client-dis'>
                                                    <strong>{project?.userCount}</strong>
                                                    <p>People</p>
                                                </div>
                                            </div>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.974" height="20.443" viewBox="0 0 20.974 20.443">
                                                        <g id="task" transform="translate(-0.3 -0.3)">
                                                            <path id="Path_1" data-name="Path 1" d="M3.574,20.743.3,17.469,1.539,16.23l2.035,2.035L7.38,14.46,8.619,15.7Zm17.7-2.124H10.654v-1.77h10.62Zm-17.7-4.956L.3,10.389,1.539,9.15l2.035,2.035L7.38,7.38,8.619,8.619Zm17.7-2.124H10.654V9.769h10.62ZM3.574,6.583.3,3.309,1.539,2.07,3.574,4.105,7.38.3,8.619,1.539Zm17.7-2.124H10.654V2.689h10.62Z" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className='client-dis'>
                                                    <strong>{project?.taskCount}</strong>
                                                    <p>Tasks</p>
                                                </div>
                                            </div>
                                            <div className='client-box'>
                                                <div className='client-box-icon'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.213" height="23.1" viewBox="0 0 20.213 23.1">
                                                        <path id="Icon_metro-files-empty" data-name="Icon metro-files-empty" d="M23.759,9.983a20.451,20.451,0,0,0-1.967-2.249,20.46,20.46,0,0,0-2.249-1.967,3.6,3.6,0,0,0-2.051-.952h-8.3a1.807,1.807,0,0,0-1.8,1.8v16.6a1.807,1.807,0,0,0,1.8,1.8H22.907a1.807,1.807,0,0,0,1.8-1.8V12.034a3.6,3.6,0,0,0-.952-2.051ZM20.772,8.755a19.691,19.691,0,0,1,1.637,1.836H18.936V7.118a19.676,19.676,0,0,1,1.836,1.637Zm2.5,14.469a.366.366,0,0,1-.361.361H9.191a.366.366,0,0,1-.361-.361V6.62a.366.366,0,0,1,.361-.361h8.3v5.053a.722.722,0,0,0,.722.722h5.053ZM16.656,2.88A3.6,3.6,0,0,0,14.6,1.928H6.3a1.807,1.807,0,0,0-1.8,1.8v16.6A1.808,1.808,0,0,0,5.942,22.1V3.733A.366.366,0,0,1,6.3,3.372H17.287c-.218-.179-.429-.344-.631-.492Z" transform="translate(-4.499 -1.928)" />
                                                    </svg>

                                                </div>
                                                <div className='client-dis'>
                                                    <strong> {project?.totalFilesCount}</strong>
                                                    <p>Files</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                            : 'No project Found'
                    }
                </div>
            </div>
                {
                    stars.length>0?
                        stars.map((star) =>  
                            <Tooltip anchorSelect={`#description${star?.id}`} clickable>
                                <span style={{color:'black'}}>{star?.description}</span>
                            </Tooltip>
                    ):''
                }
                <Modal centered show={show} onHide={handleClose} className='add-announcement-modal'>
                <Modal.Header closeButton>
                    <h4 className="modal-title">
                        Edit Announcement
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="calender-box">
                        <div className='sr-announcement'>
                            <div className='sr-announces sr-annouce-2'>
                                <label>Announcement Bar Content</label>
                                <div className="input-group">
                                    <input
                                        name="content"
                                        id="content"
                                        value={content}
                                        onChange={handleContentChange}
                                        placeholder="Enter Announcement content here"
                                    />
                                </div>
                            </div>
                            <div className='button-global-submit sr-announces sr-annouce-3'>
                                <button className='global-icon-btn orange-btn' onClick={handleButtonClick}>Submit</button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <ArchiveClientModal showModal={showModal} setShowModal={setShowModal} id={id} />
            <UnArchieveClientModal showUnModal={showUnModal} setShowUnModal={setShowUnModal} id={id}/>
            <ToastContainer
                position="top-right"
                autoClose={2500}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme='light'
            />
            
        </div>
    );
}

export default ClientDetail