import React, { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import API from '../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';

function EditTeam() {
  const [formData, setFormData] = useState({
    id: '',
    photo: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmpassword: '',
    mobileNumber: '',
    roleId: ''
  });

  const [existingData, setExistingData] = useState({}); // Store existing member data
  const [imagePreview, setImagePreview] = useState(null);
  const { id } = useParams();
  const [errors, setErrors] = useState({});
  const [files, setFile] = useState({});
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileURL, setprofilePicURL] = useState({});
  const fileInputRef = useRef(null); // To access the file input element


  const fetchTheprofilePic = async () => {
      try {
          const token = localStorage.getItem('token');
          const response = await API.get(`/admin/fetchTheprofilePicTeam/${id}`, {
              headers: {
                  'authorization': token,
                  'Content-Type': 'application/json'
              }
          });
          setprofilePicURL(response.data.profilePicUrl);
          console.log('response', response.data.profilePicUrl);
      } catch (e) {
          console.log(e);
      }
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFile(file);
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    let newErrors = {};

    // Validation
    if (formData.firstName.trim() === '') {
      newErrors.firstName = 'First name is required';
    }
    if (formData.lastName.trim() === '') {
      newErrors.lastName = 'Last name is required';
    }
    if (formData.email.trim() === '') {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Invalid email format';
    }
    if (formData.roleId === '') {
      newErrors.roleId = 'User role is required';
    }

    if (Object.keys(newErrors).length === 0) {
      const token = localStorage.getItem('token');
      const data = new FormData();

      // Create updated data based on changed fields
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== existingData[key]) {
          data.append(key, formData[key]);
        }
      });

      if (files) {
        data.append('media', files);
      }

      API.post(`/admin/updateTeam/${id}`, data, {
        headers: { Authorization: `Bearer ${token}` }
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Member updated successfully");
          setTimeout(() => navigate('/team'), 2000);
        } else if (response.status === 203) {
          toast.error("Email Already Exists");
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Member not updated");
      })
      .finally(() => {
        setLoading(false);
      });
    } else {
      setErrors(newErrors);
      setLoading(false);
    }
  };

   // Handle clicking on the displayed image
   const handleImageClick = () => {
    if (fileInputRef.current) {
        fileInputRef.current.click(); // Trigger the hidden file input when image is clicked
    }
};


  const getMemberDetail = () => {
    API.get(`admin/memberDetail/${id}`)
      .then((response) => {
        if (response.status === 200) {
          const member = response.data.data;
          console.log("member", member);
          setExistingData(member); 
          setFormData({
            id: member.id || '',
            firstName: member.firstName || '',
            lastName: member.lastName || '',
            email: member.email || '',
            mobileNumber: member.mobileNumber || '',
            roleId: member.roleId || '',
            password: '',
            confirmpassword: ''
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    const title = "Update Team Member";
    dispatch(pageTitle(title));
    getMemberDetail();
    fetchTheprofilePic();
  }, [dispatch, id]);

  return (
    <div className="main-dashboard-admin sr-cstm-padding">
      {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}>
        <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
      </button> */}
      {loading && (
        <div className='rotate-line-loader'>
          <Bars visible={true} height="150" width="150" color="orange" />
        </div>
      )}
      <div className="form-add-member">
      <button className='btn btn-primary back-to-previous mb-2'>
          <i class="fas fa-sign-out-alt"></i>
      </button>
      <div class="form-pro-img">
        <div className="pro-img-tag" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                          {imagePreview ? (
                            <>
                              <img src={imagePreview} alt="Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                              <i class="far fa-edit"></i>
                              </>
                          ) : (
                            <>
                              <img src={profileURL} alt="Profile" style={{ maxWidth: '250px', height: 'auto' }} />
                              <i class="far fa-edit"></i>
                              </>
                          )}
                      </div>   
                    </div>
                         <form onSubmit={handleSubmit}>
          <input
                            type="file"
                            name="media"
                            id="input-file"
                            accept="image/*"
                            ref={fileInputRef} // Reference to the file input
                            onChange={handlePhotoChange}
                            style={{ display: 'none' }} // Hidden input
                        />          
         
          {errors.photo && <p style={{ color: 'red' }}>{errors.photo}</p>}

          <div className="fields-row">
            <div className="single-field-form">
              <label>First Name:</label>
              <input type="text" name="firstName" maxLength={15} value={formData.firstName} onChange={handleChange} />
              {errors.firstName && <p style={{ color: 'red' }}>{errors.firstName}</p>}
            </div>
            <div className="single-field-form">
              <label>Last Name:</label>
              <input type="text" name="lastName" maxLength={15} value={formData.lastName} onChange={handleChange} />
              {errors.lastName && <p style={{ color: 'red' }}>{errors.lastName}</p>}
            </div>
            <div className="single-field-form">
              <label>Email Address:</label>
              <input type="email" name="email" value={formData.email} onChange={handleChange} />
              {errors.email && <p style={{ color: 'red' }}>{errors.email}</p>}
            </div>
            <div className="single-field-form">
              <label>Password:</label>
              <input type="password" name="password" value={formData.password} onChange={handleChange} />
              {errors.password && <p style={{ color: 'red' }}>{errors.password}</p>}
            </div>
            <div className="single-field-form">
              <label>Confirm Password:</label>
              <input type="password" name="confirmpassword" value={formData.confirmpassword} onChange={handleChange} />
              {errors.confirmpassword && <p style={{ color: 'red' }}>{errors.confirmpassword}</p>}
            </div>
            <div className="single-field-form">
              <label>User Role:</label>
              <select name="roleId" value={formData.roleId} onChange={handleChange}>
                <option value="">Please Select</option>
                <option value="1">Admin</option>
                <option value="2">Manager</option>
              </select>
              {errors.roleId && <p style={{ color: 'red' }}>{errors.roleId}</p>}
            </div>
          </div>

          <div className="form-btn-rows">
            <button className="global-icon-btn orange-btn" type="submit">
              Save
            </button>
            <Link to={'/team'} className="global-icon-btn">
              Cancel
            </Link>
          </div>
        </form>
      </div>
      <ToastContainer position="top-right" autoClose={2500} />
    </div>
  );
}

export default EditTeam;
