import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import Card from 'react-bootstrap/Card';
import { MultiSelect } from "react-multi-select-component";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import shortid from "https://cdn.skypack.dev/shortid@2.2.16";
import { ToastContainer, toast } from 'react-toastify';
// import socketIOClient from 'socket.io-client';
import socket from '../../socket';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import DiscusionReactions from './Modals/discusionReactions';

function DiscussionDetail() {
    const dispatch = useDispatch();
    const title = 'Discussion Detail';
    const { id } = useParams();
    const navigate = useNavigate();
    const [discussion, setDiscussionDetail] = useState({});
    const [teamMembers, setTeamMembers] = useState({});
    const [awaitingTeamMembers, setawaitingTeamMembers] = useState(null);
    const [selected, setSelected] = useState([]);
    const [usersOutFromDisscusion, setUsersOutFromDiscussion] = useState([]);
    const keys = { id: '', comment: '', commentableId: id };
    const [formData, setFormData] = useState(keys);
    const [filesData, setFilesData] = useState({ files: '' })
    const [errors, setErrors] = useState(keys);
    const [editable, setEditable] = useState(null);
    const [selectedfile, SetSelectedFile] = useState([]);
    const [showAddTeamMember, setShowAddTeamMember] = useState(null);
    let options = [];
    let fruits = [1, 2, 3, 6, 9];
    const [response, setResponse] = useState("");
    const [deleteableId, setDeleteableId] = useState('');
    const [show, setShow] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;
    const [showModal, setShowModal] = useState(false);
    const [visibleApproveLists, setVisibleApproveLists] = useState({});
    const [visibleApproveDLists, setVisibleApproveDLists] = useState(false);
    const [selectedCommentId, setSelectedCommentId] = useState('');
    const [reaction, setReaction] = useState(null);


    const handleReactionClick = async (type, commentId) => {
      setReaction(type);
  
      const reactionData = {
        discussion_id: id,
        user: userData.user.id,
        comment_id: commentId,
        like: type === 'like' ? true : null,
        dislike: type === 'dislike' ? true : null,
        love: type === 'love' ? true : null,
        approved: type === 'approved' ? true : null,
        not_approved: type === 'not_approved' ? true : null,
      };
  
      try {
        const response = await API.post(`/admin/reactions`, reactionData);
        console.log('Reaction response:', response.data);
        getDiscussionsDetail();
      } catch (error) {
        console.error('Error:', error.response ? error.response.data : error.message);
      }
    };
  

    if (awaitingTeamMembers && awaitingTeamMembers.length > 0) {
        awaitingTeamMembers.map((team) => {
            if (team.id != id) {
                options.push({ label: team['firstName'] + ' ' + team['lastName'], value: team['id'], 'discussionId': id })  //second one is dusscussion id 
            }
        });
    }

    const toggleApproveLists = (commentId) => {
        setVisibleApproveLists(prevState => ({
            ...prevState,
            [commentId]: !prevState[commentId] // Toggle     the specific comment's approve-lists visibility
        }));
    };

    const handleDiscussionToggle = () => {
        setVisibleApproveDLists(prevState => !prevState);
    };
    
    const filesizes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    //on page load
    const getDiscussionsDetail = () => {
        API.get(`/admin/discussion-detail/${id}`)
            .then((response) => {
                console.log("data details", response.data);
                setDiscussionDetail(response.data.detail);
                setTeamMembers(response.data.team);
                setawaitingTeamMembers(response.data.awaitingTeam);
                let users = response.data.users.map(a => a.userId);
                setUsersOutFromDiscussion(users);
                // console.log(users);
            }).catch((e) => {
                console.log(e);
            })
    }

    const handleShowModal = (commentId) => {
        setShowModal(true);
        setSelectedCommentId(commentId); 

    };

    //add remove member for discussion
    const handleClick = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const ids = {};
        const allIds = { ...ids, 'discussionId': id, [name]: value, 'value': e.target.checked };
        API.post('/admin/add-remove-member-for-discussion', allIds)
            .then((response) => {
                console.log(response.data.data);
                getDiscussionsDetail()
            }).catch((e) => {
                console.log(e);
            });

    }

    //add team members for project
    const handleSelector = () => {
        API.post('/admin/addMemberInProjectFromDiscussion', selected)
            .then((response) => {
                setSelected([]);
                getDiscussionsDetail()
            }).catch((error) => {
                console.log(error);
            });
    }

    //input change for file
    const handleChange = (e) => {
        const { name, value } = e.target;
        const file = e.target.files;
        if (file) {
            setFilesData({ ...filesData, [name]: file });
        }
    }
    function Capitalize(str) {
        console.log(str)
        let strt = 'abc'
        return strt.charAt(0).toUpperCase() + strt.slice(1);
    }

    const fileChange = (e) => {
        // --For Multiple File Input 
        for (let i = 0; i < e.target.files.length; i++) {
            let reader = new FileReader();
            let file = e.target.files[i];
            reader.onloadend = () => {
                SetSelectedFile(
                    (preValue) => {
                        return [
                            ...preValue,
                            {
                                id: shortid.generate(),
                                filename: e.target.files[i].name,
                                filetype: e.target.files[i].type,
                                fileimage: reader.result,
                                datetime: e.target.files[i].lastModifiedDate.toLocaleString('en-IN'),
                                filesize: filesizes(e.target.files[i].size)
                            }
                        ]
                    });
            }

            if (e.target.files[i]) {
                reader.readAsDataURL(file);
            }
        }
    }

    const DeleteSelectFile = (id) => {
        if (window.confirm("Are you sure you want to delete this Image?")) {
            const result = selectedfile.filter((data) => data.id !== id);
            SetSelectedFile(result);
        } else {
            // alert('No');
        }

    }

    function handleErrors() {
        let errors = null;
        if (formData.comment.trim() == '') {
            errors = true;
            setErrors({ ...errors, ['comment']: 'Please add comment first' })
        }
        else if (filesData.files != "") {
            const validExtensions = ['jpg', 'jpeg', 'png', 'csv', 'doc', 'html', 'xml', 'zip', 'pdf', 'mp4'];
            for (let i = 0; i < filesData.files.length; i++) {
                const extension = filesData.files[i].name.split('.').pop().toLowerCase();
                if (!validExtensions.includes(extension)) {
                    errors = true;
                    setErrors({ ...errors, 'file': 'File extension is not allowed' });
                }
            }
        }
        return errors;
    }
    //on submittion of comment
    const handleSubmit = (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        let form = new FormData();
        // Append each file to the form data
        for (let i = 0; i < filesData.files.length; i++) {
            form.append('files', filesData.files[i]);
        }
        Object.keys(formData).forEach(key => {
            form.append(key, formData[key])
        });
        form.append('ActiveuserId', ActiveuserId);
        form.append('TeamMembers', JSON.stringify(teamMembers?.users));

        console.log("form", form);
        console.log("forfdfsfsdfdfsdfdfsdm");
        const errors = handleErrors();
        if (!errors) {
            if (formData.id !== '') {
                API.post('/admin/update-comment-into-project-discussion', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then((response) => {
                        toast.success('Comment updated successfully')
                        setFormData(keys);
                        getDiscussionsDetail();
                        console.log(response);
                    }).catch((error) => {
                        console.log(error);
                        toast.error('Something went wrong')
                    });
            }
            else {
                API.post('/admin/add-new-comment-into-project-discussion', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    }
                })
                    .then((response) => {
                        setFormData(keys);
                        getDiscussionsDetail();

                        console.log(response);
                    }).catch((error) => {
                        console.log(error);
                        toast.error('Something went wrong')
                    });
            }
        }
    }

    //edit comment 
    const handleEdit = (e) => {
        setErrors(null);
        const commmentId = e.target.value;
        setFormData({
            ...formData, id: commmentId
        });
        API.get(`/admin/find-discussion-comment/${commmentId}`).
            then((response) => {
                const data = response.data.data;
                setFormData({
                    comment: data.comment,
                });
                setEditable(response.data.data);
            }).catch((e) => {
                console.log(e);
            })
    }

    //comment delete
    const handleDelete = (e) => {
        setEditable(null);
        setFormData(keys);
        const { value } = e.target;
        setDeleteableId(value);
        setShow(true);
    }
    const handleConfirmDelete = () => {
        console.log("Handle Confirm Delete", ActiveuserId);
        API.get(`/admin/delete-discussion-comment/${deleteableId}/${ActiveuserId}`).
            then((response) => {
                toast.success('Comment deleted successfully')
                getDiscussionsDetail();
                setShow(false);
            }).catch((e) => {
                console.log(e);
                toast.error('Something went wrong')
                setShow(false);
            });
    }
    const handleClose = () => {
        setShow(false);
    }
    const handleCancel = (e) => {
        setFormData(keys)
        setEditable(null)
    }
    const handleDiscussionDelete = (e) => {
        const id = e.target.value;
        const token = localStorage.getItem('token');
        API.get(`/admin/delete-discussion/${id}`)
            .then((response) => {
                toast.success('Discussion deleted successfully')
                // getDiscussionsDetail();
                setTimeout(() => navigate(`/clients/client-detail/project-detail/discussions/${id}`), 1000);
            }).catch((e) => {
                toast.error('Something went wrong')
                console.log(e);
            })
    }

    const AddMembersPopup = () => setShowAddTeamMember(true);
    const closeAddMembersPopup = () => setShowAddTeamMember(false);

    useEffect(() => {
        if (selected.length > 0) {
            handleSelector();
        }
        else {
            dispatch(pageTitle(title));
            getDiscussionsDetail();
        }
        socket.on('server_message', (message) => {
            setDiscussionDetail(message);
            // console.log(message)
        });

    }, [selected])

    return (
        <div className='main-dashboard-admin'>
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i class="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
            <div className="container">

            </div>
            <div className='details-discussion-outer'>
                <div className='detail-grid left'>
                    <div class="dis-head">
                    <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i class="fas fa-sign-out-alt"></i></button>
                    <h2>  {discussion?.title} D - {discussion?.serialNo}</h2>
                    </div>
                    {/* <button className="sr-cstm-primary" >Reactions</button> */}

                    {Object.keys(discussion).length > 0 ?
                        <div className='details-boxes'>
                            <div className="project-sr">
                                <div className="project-peo-wrap">
                                    <div className='sr-cstm-div-img-date'>

                                        <div className="project-peo-img">
                                            <img src={discussion?.addedByUser?.profilePictureUrl} alt='client' />
                                            <h4>{discussion?.addedByUser?.firstName}</h4>
                                        </div>
                                        <div className="project-peo-cont">
                                            <div className='sr-react-date'>
                                                {/* <div className='sr-react'>
                                                    <div className='dis-btn'>
                                                        <div class="dislike"><img src="/assets/images/like.png"></img></div>
                                                        <div class="heart"><img src="/assets/images/heart.png"></img></div>
                                                    </div>
                                                    <p>You and 32 Others</p>
                                                </div> */}

                                                <div className='edits-calender'>
                                                    <i className="fas fa-calendar-check"></i>
                                                    <p> {new Date(discussion?.createdAt).toLocaleString()}</p>
                                                </div>
                                            </div>
                                            {/* <p dangerouslySetInnerHTML={{ __html: discussion?.description }} /> */}
                                        </div>
                                    </div>
                                    {
                                        discussion?.storages.length > 0 ?
                                            discussion?.storages.map(storage =>
                                                <div className="filesContainer">
                                                    {
                                                        (storage.storageKey.split('.').pop() == 'jpg' || storage.storageKey.split('.').pop() == 'jpeg') || (storage.storageKey.split('.').pop() == 'png') || (storage.storageKey.split('.').pop() == 'svg') ?
                                                            <a href={storage.file}><img src={storage.file} alt="file" /></a>
                                                            :
                                                            (storage.storageKey.split('.').pop() == 'csv')
                                                                ?
                                                                <a href={storage.file}>
                                                                    <img src="/assets/images/csv-svgrepo-com.svg" alt="file" />
                                                                </a>
                                                                :
                                                                (storage.storageKey.split('.').pop() == 'doc')
                                                                    ?
                                                                    <a href={storage.file}>
                                                                        <img src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="file" />
                                                                    </a>
                                                                    :
                                                                    (storage.storageKey.split('.').pop() == 'html')
                                                                        ?
                                                                        <a href={storage.file}>
                                                                            <img src="/assets/images/html-5-svgrepo-com.svg" alt="file" />
                                                                        </a>
                                                                        :
                                                                        (storage.storageKey.split('.').pop() == 'xml')
                                                                            ?
                                                                            <a href={storage.file}>
                                                                                <img src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="file" />
                                                                            </a>
                                                                            :
                                                                            (storage.storageKey.split('.').pop() == 'zip')
                                                                                ?
                                                                                <a href={storage.file}>
                                                                                    <img src="/assets/images/compressed-file-zip-svgrepo-com.svg" alt="file" />
                                                                                </a>
                                                                                :
                                                                                (storage.storageKey.split('.').pop() == 'pdf')
                                                                                    ?
                                                                                    <a href={storage.file}>
                                                                                        <iframe src={storage.file} style={{ width: '100%', height: '600px' }} />
                                                                                    </a>
                                                                                    :
                                                                                    (storage.storageKey.split('.').pop() == 'mp4')
                                                                                        ?
                                                                                        <a href={storage.file}>
                                                                                            {/* <img src="/assets/images/video-streaming-outline-svgrepo-com.svg" alt="file"/> */}
                                                                                            <video src={storage.file} width="750" height="500" controls></video>
                                                                                        </a>
                                                                                        :
                                                                                        <img src="/assets/images/file-unknown.svg" alt="file" />
                                                    }
                                                </div>
                                            )
                                            : null
                                    }
                                    <div className='react-post'>
                                        {/* <div className='react-inner-d'  onClick={() => handleDiscussionToggle()}>
                                            <p>React to this post</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.003" height="15.003" viewBox="0 0 15.003 15.003">
                                            <g id="Icon_feather-smile" data-name="Icon feather-smile" transform="translate(-2.25 -2.25)">
                                                <path id="Path_1" data-name="Path 1" d="M16.5,9.751A6.751,6.751,0,1,1,9.751,3,6.751,6.751,0,0,1,16.5,9.751Z" transform="translate(0 0)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_2" data-name="Path 2" d="M12,21a3.645,3.645,0,0,0,2.7,1.35A3.645,3.645,0,0,0,17.4,21" transform="translate(-4.949 -9.898)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_3" data-name="Path 3" d="M13.5,13.5h.007" transform="translate(-5.774 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_4" data-name="Path 4" d="M22.5,13.5h.007" transform="translate(-10.723 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            </g>
                                            </svg>
                                        </div> */}
                                        {visibleApproveDLists && (
                                        <div className='approve-lists sr-approve-lists'>
                                            <ul>
                                                <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                    <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M10.782,7.539V4.945A1.945,1.945,0,0,0,8.836,3L6.242,8.836V15.97h7.315a1.3,1.3,0,0,0,1.3-1.1l.895-5.836a1.3,1.3,0,0,0-1.3-1.492ZM6.242,15.97H4.3a1.3,1.3,0,0,1-1.3-1.3V10.133a1.3,1.3,0,0,1,1.3-1.3H6.242" transform="translate(-2.25 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Like</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                    <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M7.982,11.43v2.594A1.945,1.945,0,0,0,9.927,15.97l2.594-5.836V3H5.207a1.3,1.3,0,0,0-1.3,1.1L3.015,9.939a1.3,1.3,0,0,0,1.3,1.492ZM12.521,3h1.945a1.3,1.3,0,0,1,1.3,1.3V8.836a1.3,1.3,0,0,1-1.3,1.3H12.521" transform="translate(-2.247 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Dislike</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="15.788" height="14.574" viewBox="0 0 15.788 14.574">
                                                    <path id="Icon_ionic-md-heart-empty" data-name="Icon ionic-md-heart-empty" d="M14.821,4.5a4.67,4.67,0,0,0-3.552,1.666A4.67,4.67,0,0,0,7.717,4.5,4.292,4.292,0,0,0,3.375,8.868c0,3.017,2.683,5.439,6.748,9.173l1.146,1.032,1.146-1.032c4.065-3.734,6.748-6.156,6.748-9.173A4.292,4.292,0,0,0,14.821,4.5ZM11.755,17l-.159.148-.326.3-.326-.3L10.783,17a48.239,48.239,0,0,1-4.657-4.63,5.568,5.568,0,0,1-1.533-3.5,3.157,3.157,0,0,1,.9-2.251,3.089,3.089,0,0,1,2.224-.9,3.481,3.481,0,0,1,2.622,1.233l.93,1.1.93-1.1a3.468,3.468,0,0,1,2.622-1.233,3.1,3.1,0,0,1,2.228.9,3.163,3.163,0,0,1,.9,2.251,5.6,5.6,0,0,1-1.533,3.5A48.337,48.337,0,0,1,11.755,17Z" transform="translate(-3.375 -4.5)" fill="#fff"/>
                                                    </svg>
                                                    <span>Love</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="18.518" height="13.67" viewBox="0 0 18.518 13.67">
                                                    <path id="Icon_feather-check" data-name="Icon feather-check" d="M22.4,9,11.124,20.86,6,15.469" transform="translate(-4.94 -7.94)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Approved</span></a></li>
                                                    <li><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143">
                                                    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(7.072 -14.85) rotate(45)">
                                                        <path id="Path_8" data-name="Path 8" d="M18,7.5v16" transform="translate(-2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                        <path id="Path_9" data-name="Path 9" d="M7.5,18h16" transform="translate(0 -2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    </g>
                                                    </svg>
                                                    <span>Not Approved</span></a></li>
                                            </ul>
                                        </div>
                                         )}
                                    </div>
                                    <div className='details-edits-cal'>
                                        <p dangerouslySetInnerHTML={{ __html: discussion?.description }} ></p>
                                    </div>
                                </div>
                            </div>

                        </div>
                        : ''
                    }
                    {
                        discussion?.comments?.length > 0 &&
                        discussion.comments.map((comment) => (
                            
                            <div className='details-boxes' key={comment.id}>
                                <div className='project-sr'>
                                    <div className='project-peo-wrap'>
                                        <div className='sr-cstm-div-img-date'>
                                            <div className='project-peo-img'>
                                                <img src={comment?.user?.profilePictureUrl} alt='client' />
                                            </div>
                                            <div className='project-peo-cont'>
                                                <h4>{comment?.user?.firstName}</h4>
                                                <div className='sr-react-date'>
                                                {comment?.reactions && comment.reactions.length > 0 ? (
                                                <div className='sr-react' onClick={() => handleShowModal(comment.id)}>
                                                    <div className="dis-btn">
                                                        <div class="dislike"><img src="/assets/images/like.png"></img></div>
                                                        <div class="heart"><img src="/assets/images/heart.png"></img></div>
                                                    </div>

                                                    {/* {comment?.reactions?.length || 0} */}
                                                    <p>
                                                        
                                                        <>
                                                            {comment.reactions[0]?.Reacteduser?.firstName || 'Unknown'}
                                                            {comment.reactions.length > 1 ? ` & ${comment.reactions.length - 1} Others` : ''}
                                                        </>
                                                       
                                                    </p>
                                                    </div>
                                                     ) : ''}
                                                <div className='edits-calender'>
                                                    <i className="fas fa-calendar-check"></i>
                                                    <p> {new Date(comment?.createdAt).toLocaleString()}</p>
                                                </div>
                                                {/* <p dangerouslySetInnerHTML={{ __html: comment.comment }} /> */}
                                            </div>
                                            </div>
                                        </div>
                                        {
                                            comment.storages.length > 0 ?
                                                comment.storages.map((storage, index) => (
                                                    <div className='fileContainer' key={index}>
                                                        {
                                                            // Render file icons or previews based on file type
                                                            (storage.storageKey.split('.').pop() === 'jpg' || storage.storageKey.split('.').pop() === 'jpeg' ||
                                                                storage.storageKey.split('.').pop() === 'png' || storage.storageKey.split('.').pop() === 'svg') ?
                                                                <a href={storage.file}><img src={storage.file} alt='file' /></a> :
                                                                (storage.storageKey.split('.').pop() === 'csv') ?
                                                                    <a href={storage.file}><img src='/assets/images/csv-svgrepo-com.svg' alt='file' /></a> :
                                                                    (storage.storageKey.split('.').pop() === 'doc') ?
                                                                        <a href={storage.file}><img src='/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg' alt='file' /></a> :
                                                                        (storage.storageKey.split('.').pop() === 'html') ?
                                                                            <a href={storage.file}><img src='/assets/images/html-5-svgrepo-com.svg' alt='file' /></a> :
                                                                            (storage.storageKey.split('.').pop() === 'xml') ?
                                                                                <a href={storage.file}><img src='/assets/images/xml-file-format-variant-svgrepo-com.svg' alt='file' /></a> :
                                                                                (storage.storageKey.split('.').pop() === 'zip') ?
                                                                                    <a href={storage.file}><img src='/assets/images/compressed-file-zip-svgrepo-com.svg' alt='file' /></a> :
                                                                                    (storage.storageKey.split('.').pop() === 'pdf') ?
                                                                                        <a href={storage.file}><iframe src={storage.file} style={{ width: '100%', height: '600px' }} /></a> :
                                                                                        (storage.storageKey.split('.').pop() === 'mp4') ?
                                                                                            <a href={storage.file}><video src={storage.file} width='750' height='500' controls></video></a> :
                                                                                            <img src='/assets/images/file-unknown.svg' alt='file' />
                                                        }
                                                    </div>
                                                )) : null
                                        }
                                        <div className='react-post' onClick={() => toggleApproveLists(comment.id)}>
                                        <div className='react-inner'>
                                            <p>React to this post</p>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.003" height="15.003" viewBox="0 0 15.003 15.003">
                                            <g id="Icon_feather-smile" data-name="Icon feather-smile" transform="translate(-2.25 -2.25)">
                                                <path id="Path_1" data-name="Path 1" d="M16.5,9.751A6.751,6.751,0,1,1,9.751,3,6.751,6.751,0,0,1,16.5,9.751Z" transform="translate(0 0)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_2" data-name="Path 2" d="M12,21a3.645,3.645,0,0,0,2.7,1.35A3.645,3.645,0,0,0,17.4,21" transform="translate(-4.949 -9.898)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_3" data-name="Path 3" d="M13.5,13.5h.007" transform="translate(-5.774 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                                <path id="Path_4" data-name="Path 4" d="M22.5,13.5h.007" transform="translate(-10.723 -5.774)" fill="none" stroke="#9a9a9a" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" />
                                            </g>
                                            </svg>
                                        </div>
                                        {visibleApproveLists[comment.id] && (

                                        <div className='approve-lists'>
                                            <ul>
                                                <li onClick={() => handleReactionClick('like', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                    <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M10.782,7.539V4.945A1.945,1.945,0,0,0,8.836,3L6.242,8.836V15.97h7.315a1.3,1.3,0,0,0,1.3-1.1l.895-5.836a1.3,1.3,0,0,0-1.3-1.492ZM6.242,15.97H4.3a1.3,1.3,0,0,1-1.3-1.3V10.133a1.3,1.3,0,0,1,1.3-1.3H6.242" transform="translate(-2.25 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Like</span></a></li>
                                                    <li onClick={() => handleReactionClick('dislike', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
                                                    <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M7.982,11.43v2.594A1.945,1.945,0,0,0,9.927,15.97l2.594-5.836V3H5.207a1.3,1.3,0,0,0-1.3,1.1L3.015,9.939a1.3,1.3,0,0,0,1.3,1.492ZM12.521,3h1.945a1.3,1.3,0,0,1,1.3,1.3V8.836a1.3,1.3,0,0,1-1.3,1.3H12.521" transform="translate(-2.247 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Dislike</span></a></li>
                                                    <li onClick={() => handleReactionClick('love', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="15.788" height="14.574" viewBox="0 0 15.788 14.574">
                                                    <path id="Icon_ionic-md-heart-empty" data-name="Icon ionic-md-heart-empty" d="M14.821,4.5a4.67,4.67,0,0,0-3.552,1.666A4.67,4.67,0,0,0,7.717,4.5,4.292,4.292,0,0,0,3.375,8.868c0,3.017,2.683,5.439,6.748,9.173l1.146,1.032,1.146-1.032c4.065-3.734,6.748-6.156,6.748-9.173A4.292,4.292,0,0,0,14.821,4.5ZM11.755,17l-.159.148-.326.3-.326-.3L10.783,17a48.239,48.239,0,0,1-4.657-4.63,5.568,5.568,0,0,1-1.533-3.5,3.157,3.157,0,0,1,.9-2.251,3.089,3.089,0,0,1,2.224-.9,3.481,3.481,0,0,1,2.622,1.233l.93,1.1.93-1.1a3.468,3.468,0,0,1,2.622-1.233,3.1,3.1,0,0,1,2.228.9,3.163,3.163,0,0,1,.9,2.251,5.6,5.6,0,0,1-1.533,3.5A48.337,48.337,0,0,1,11.755,17Z" transform="translate(-3.375 -4.5)" fill="#fff"/>
                                                    </svg>
                                                    <span>Love</span></a></li>
                                                    <li onClick={() => handleReactionClick('approved', comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="18.518" height="13.67" viewBox="0 0 18.518 13.67">
                                                    <path id="Icon_feather-check" data-name="Icon feather-check" d="M22.4,9,11.124,20.86,6,15.469" transform="translate(-4.94 -7.94)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>
                                                    </svg>
                                                    <span>Approved</span></a></li>
                                                    <li onClick={() => handleReactionClick('not_approved',comment.id)}><a href="javascript:void(0)"><svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143">
                                                    <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(7.072 -14.85) rotate(45)">
                                                        <path id="Path_8" data-name="Path 8" d="M18,7.5v16" transform="translate(-2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                        <path id="Path_9" data-name="Path 9" d="M7.5,18h16" transform="translate(0 -2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                    </g>
                                                    </svg>
                                                    <span>Not Approved</span></a></li>
                                            </ul>
                                        </div>
                                         )}
                                    </div>
                                    <div className='edit-delete'>
                                                {
                                                    // Render Edit and Delete buttons only if the comment author's ID matches ActiveuserId and it's within 15 minutes of posted time
                                                    comment.user.id === ActiveuserId && new Date() - new Date(comment.createdAt) <= 15 * 60 * 1000 && (
                                                        <>
                                                            <button className='btn' name='comment_id' value={comment.id} onClick={handleEdit}><i className="fas fa-pencil-alt"></i></button>
                                                            &nbsp;<span>|</span>&nbsp;
                                                            <button className='btn' name='comment_id' value={comment.id} onClick={handleDelete}> <i className="far fa-trash-alt"></i></button>
                                                        </>
                                                    )
                                                }
                                            </div>
                                        <div className='details-edits-cal'>
                                            {/* <div className='edits-calender'>
                                                <i className='fas fa-calendar-check'></i>
                                                <p>{new Date(comment?.createdAt).toLocaleString()}</p>
                                            </div> */}
                                            <p dangerouslySetInnerHTML={{ __html: comment.comment }} ></p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        ))
                    }

                    <h5> Leave A New Message</h5>
                    <form onSubmit={handleSubmit} encType="multipart/form-data">
                        <div className='discuss-edit-inner'>
                            <CKEditor
                                editor={ClassicEditor}
                                data={formData ? formData.comment : ''}
                                onChange={(event, editor) => {
                                    setFormData({ ...formData, comment: editor.getData() });
                                }}
                            />
                            <p style={{ color: 'red' }}>{errors?.comment}</p>
                            <div className="upload-btn-wrapper mt-3">
                                <button className="attached-btn">
                                    <i className="fas fa-paperclip"></i>Attach Files
                                </button>
                                <input type="file" name="files" id="fileupload" className="file-upload-input" onChange={handleChange} multiple />
                                {/* <span className='text-danger'>{errors?.file}</span> */}
                            </div>
                        </div>
                        {/* <button>sd</button> */}
                        {/* <button className='btn btn-success mt-1' type="submit"> { editable ? 'Update this comment' : 'Add this comment' }</button> */}
                        <button className="global-icon-btn orange-btn" onClick={handleSubmit}>
                            {editable ? 'Update Message' : 'Add Message'}</button>
                        {/* <button className='btn btn-primary' type="button" onClick={handleCancel}> Cancel </button> */}
                    </form>
                    <DiscusionReactions showModal={showModal} setShowModal={setShowModal} id={id}  discussion={discussion}  commentId={selectedCommentId} />

                </div>
                <div className='detail-grid right'>
                    {/* <h2>Related Tasks & Issues</h2> */}
                    <div className=" det-table">
                        <div className='pass-card-out'>
                            <h2>Team Members</h2>
                            <div className="password-card check">
                                <div className="psw-tems-btns">
                                    <div className="psw-team-btn"><button>Team</button></div>
                                    <div className="disc-edit-txt"><p>Check to enable email alerts.</p></div>
                                </div>
                                <div className="pass-check-in dt">
                                    {
                                        Object.keys(teamMembers).length > 0 ?
                                            teamMembers.users.length > 0 ?
                                                <div className="row">
                                                    {
                                                        teamMembers.users.map((team) =>
                                                            <div className="pass-check">
                                                                <input type="checkbox" name='userId' value={team.id} onChange={handleClick} checked={usersOutFromDisscusion.includes(team.id) ? false : true} />
                                                                <div className="project-peo-img"><img src={team.profilePictureUrl} /></div>
                                                                <div className="project-peo-cont"><p>{team?.firstName + ' ' + team?.lastName + ' - ' + team?.email}</p></div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                : 'No team member found'
                                            : 'No team member found'
                                    }
                                    <div className="add-mem">
                                        <a href="javascript:void(0)">
                                            {
                                                showAddTeamMember ?
                                                    <i className="fa fa-window-close" onClick={closeAddMembersPopup}></i>
                                                    :
                                                    <i className="fas fa-plus" onClick={AddMembersPopup}><span> Invite More Team Members</span></i>
                                            }
                                            </a>
                                        {
                                            showAddTeamMember ?
                                                <MultiSelect
                                                    options={options}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="sadsa"
                                                />
                                                : ''
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal centered show={show} onHide={handleClose} className='add-team-modal'>
                <Modal.Header closeButton>
                    <h4 className="modal-title">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="19.2"
                            viewBox="0 0 24 19.2"
                        >
                            <path
                                id="Icon_awesome-user-plus"
                                data-name="Icon awesome-user-plus"
                                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
                            />
                        </svg>
                        Delete Discussion
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="calender-box">
                        <div className="question-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="86.875" height="86.875" viewBox="0 0 86.875 86.875">
                                <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z" transform="translate(-0.563 -0.563)" />
                            </svg>
                        </div>
                        <p>Are you sure you want to Delete Discussion ?</p>
                    </div>
                    <div className="form-btn-rows">
                        <button type="button " onClick={handleConfirmDelete} className="btn global-icon-btn orange-btn ">Yes</button>
                        <button type="button" onClick={handleClose} className="btn global-icon-btn ">No</button>
                    </div>
                </Modal.Body>
            </Modal>
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    )
}

export default DiscussionDetail