import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import API from '../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResetPasswordPage() {
    const { token } = useParams();
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);

        if (password !== confirmPassword) {
            setError('Passwords do not match');
            return;
        }

        try {
            const response = await API.post('/reset-password', { token, password });
            toast.success("Your password has been reset", {
                position: "top-center"
            });
            setTimeout(() => {
                navigate('/');
            }, 5000);
        } catch (error) {
            toast(error.response?.data?.message || 'An error occurred.');
        }
    };

    return (
        <div className="login-page">
            <div className="login-page-outer">
                <div className="logo-main-login">
                    <img src="/assets/images/logo-main.png" alt="Logo-Main" />
                </div>
                <div className="login-page-inner">
                    <h1>Reset Your Password</h1>
                    <form onSubmit={handleSubmit}>
                        {error && <span className="form-error main-error">{error}</span>}
                        <div className="single-field">
                            <label>Password</label>
                            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        <div className="single-field">
                            <label>Confirm Password</label>
                            <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
                        </div>
                        <input type="submit" value="Reset Password" />
                        {success && <div className="success">{success}</div>}
                    </form>
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
    );
}

export default ResetPasswordPage;
