import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";


function Progress() {
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [userName, setUserName] = useState('');
  const title = "Progress";
  const dispatch = useDispatch();
  const userData = useSelector(state => state.auth.user);
  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
  }
  const fetchDiscussionstoday = useCallback(async () => {
    try {
      const response = await API.get('/admin/discussions/today');
      setData(response.data.groupedProgressContent);
      console.log("Today's data", response.data.groupedProgressContent);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    fetchDiscussionstoday();
  }, [fetchDiscussionstoday]);

  useEffect(() => {
    const fetchDiscussionstoday = async () => {
      try {
        const response = await API.get('/admin/discussions/today');
        setData(response.data.groupedProgressContent);
        console.log("Today's data", response.data.groupedProgressContent);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchDiscussionstoday();
    dispatch(setBreadcrumbs([
      { label: "Home", path: "/" },
      { label: "Progress", path: "/progress", active: true }
    ]));
  }, []);


  useEffect(() => {
    dispatch(pageTitle(title));
  }, [dispatch, title, fetchDiscussionstoday]);

  useEffect(() => {
    fetchDiscussions(fromDate, toDate, userName);
  }, [fromDate, toDate, userName]);

  const handleFromDateChange = async (date) => {
    setFromDate(date);
  };

  const handleToDateChange = async (date) => {
    setToDate(date);
  };

  const fetchDiscussions = async (from, to, user) => {
    if (from && to) {
      try {
        const response = await API.get('/admin/discussions', {
          params: {
            from: from.toISOString(),
            to: to.toISOString(),
            firstName: user
          },
        });
        setData(response.data.groupedDiscussions);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };

  const formatDateDisplay = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  };

  const isToday = (date) => date.toDateString() === new Date().toDateString();
  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.toDateString() === yesterday.toDateString();
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      fetchDiscussions(fromDate, toDate, userName);
    }
  };

  return (
    <div className="main-dashboard-admin">
      <div className='checklist-header-wrap'>
        <div className='progress-radio-out date-progress-section'>
          <div className="searchBar">
            <div className="field">
              <input
                type="search"
                placeholder="Search for User"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                onKeyDown={handleKeyDown}
              />
              <button type="submit">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </div>
          <div className="select-date-pro">
            <div className='select-heading'><h4>Select Period:</h4></div>
            <div className="dateBlock">
              <div className="datePickerWrapper">
                <label htmlFor="fromDate">From Date</label>
                <DatePicker
                  id="fromDate"
                  selected={fromDate}
                  onChange={handleFromDateChange}
                  selectsStart
                  startDate={fromDate}
                  endDate={toDate}
                  maxDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select From Date"
                  className="datePickerInput"
                  customInput={
                    <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  }
                />
              </div>
              <div className="datePickerWrapper">
                <label htmlFor="toDate">To Date</label>
                <DatePicker
                  id="toDate"
                  selected={toDate}
                  onChange={handleToDateChange}
                  selectsEnd
                  startDate={fromDate}
                  endDate={toDate}
                  maxDate={new Date()}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Select To Date"
                  className="datePickerInput"
                  customInput={
                    <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className='stream infinite_page'>
        <div className='day'>
          <a href="javacript:void(0)">
            <span>
              <time className="head">Today</time>
              <time className="subhead">May 8 2024</time>
            </span>
          </a>
        </div>
        <div className='event_container'>
          <div className='project project-left-data'>
            <a href="javascript:void(0)">AllWorship</a>
          </div>
            <article className='event event-left'>
              <div className='avatar'>
                <a href="javascript:void(0)">
                  <img src="https://cdn.37img.com/global/46498488-9c82-11ed-97a9-52540070edfd/avatar.96.gif?r=3"></img>
                </a>
              </div>
              <div className='action'>
                <span class="creator" >Amit R.</span>
                <span class="in_timeline">commented on<a class="decorated">Mockup</a></span>
                <div className='time'>
                <span>Sent at 4:28am</span>
              </div>
                <div className='in_timeline comment'>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown</p>
                </div>
              </div>
            </article>
        </div>
        <div className='event_container'>
          <div className=' project project-right-data'>
            <a href="javascript:void(0)">PMT</a>
          </div>
            <article className='event event-right'>
              <div className='avatar'>
                <a href="javascript:void(0)">
                  <img src="https://cdn.37img.com/global/46498488-9c82-11ed-97a9-52540070edfd/avatar.96.gif?r=3"></img>
                </a>
              </div>
              <div className='action'>
                <span class="creator" >Amit R.</span>
                <span class="in_timeline">commented on<a class="decorated">Mockup</a></span>
                <div className='time'>
                   <span>Sent at 4:28am</span>
                </div>
                <div className='in_timeline comment'>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown</p>
                </div>
              </div>
            </article>
        </div>
        <div className='event_container'>
          <div className='project project-left-data'>
            <a href="javascript:void(0)">AllWorship</a>
          </div>
            <article className='event event-left'>
              <div className='avatar'>
                <a href="javascript:void(0)">
                  <img src="https://cdn.37img.com/global/46498488-9c82-11ed-97a9-52540070edfd/avatar.96.gif?r=3"></img>
                </a>
              </div>
              <div className='action'>
                <span class="creator" >Amit R.</span>
                <span class="in_timeline">commented on<a class="decorated">Mockup</a></span>
                <div className='time'>
                <span>Sent at 4:28am</span>
              </div>
                <div className='in_timeline comment'>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown</p>
                </div>
              </div>
            </article>
        </div>
        <div className='event_container'>
          <div className=' project project-right-data'>
            <a href="javascript:void(0)">PMT</a>
          </div>
            <article className='event event-right'>
              <div className='avatar'>
                <a href="javascript:void(0)">
                  <img src="https://cdn.37img.com/global/46498488-9c82-11ed-97a9-52540070edfd/avatar.96.gif?r=3"></img>
                </a>
              </div>
              <div className='action'>
                <span class="creator" >Amit R.</span>
                <span class="in_timeline">commented on<a class="decorated">Mockup</a></span>
                <div className='time'>
                   <span>Sent at 4:28am</span>
                </div>
                <div className='in_timeline comment'>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown</p>
                </div>
              </div>
            </article>
        </div>
      </section>
    </div>
  );
}

export default Progress;
