import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

function ArchiveTask({ showArchiveModal, setShowArchiveModal, taskId ,boardId, onTaskCreated, onArchiveSuccess}) {  
    const navigate = useNavigate();

    const handleClose = () => {
        setShowArchiveModal(false);
    };

    const handlearchive = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await API.post(`/board/archieve-task/${taskId}`, {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                setShowArchiveModal(false);
                navigate(`/viewBoard/${boardId}`); 
                onArchiveSuccess();
                toast.success('Task Archived successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error('Error while making task archived', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.error('Error archiving task:', error);
            toast.error('Error while archiving task', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    return (
        <>
            <Modal centered show={showArchiveModal} onHide={handleClose} className='add-team-modal'>
                <Modal.Header closeButton>
                    <h4 className="modal-title">
                        Archive Task
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <div className="calender-box">
                        <div className="question-icon">
                            {/* SVG Icon */}
                        </div>
                        <p>Are you sure you want to archieve this task?</p>
                    </div>
                    <div className="form-btn-rows">
                        <button type="button" onClick={handlearchive} className="btn global-icon-btn orange-btn">Yes</button>
                        <button type="button" onClick={handleClose} className="btn global-icon-btn">No</button>
                    </div>
                    <ToastContainer
                        position="top-right"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ArchiveTask; 
