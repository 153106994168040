import React, { useState, useEffect } from 'react'
import API from '../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bars } from 'react-loader-spinner';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(null);
    const [success, setSuccess] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null);
        setSuccess(null);
        setLoading(true);
        try {
            const response = await API.post('/forgot-password', { email });
            setLoading(false);
            toast.success("Reset Password Email Link has been sent", {
                position: "top-center"
            });

        } catch (error) {
            setLoading(false);
            toast(error.response?.data?.message || 'An error occurred.');
        }
    }
    return (
        <div className="login-page">
            {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <div className="login-page-outer">
                <div className="logo-main-login">
                    <img src="assets/images/logo-main.png" alt="Logo-Main" />
                </div>
                <div className="login-page-inner">
                    <form onSubmit={handleSubmit}>
                        <h1>Forgot Your Password</h1>
                        <div className="single-field">
                            <label>Email Address</label>
                            <input type="email" placeholder="Email Address" name='email' onChange={(e) => { setEmail(e.target.value) }} required />
                        </div>
                        <input type="submit" value="Reset Password" disabled={loading} />
                    </form>
                </div>
            </div>
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover  />
        </div>
    )
}

export default ForgotPassword
