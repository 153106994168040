import React, { useState } from 'react';
import Discussions from '../clients/AddDiscussion';

function FileUpload() {
  const [files, setFiles] = useState([]);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles([...files, ...selectedFiles]);
  };

  const handleDelete = (index) => {
    const updatedFiles = files.filter((file, i) => i !== index);
    setFiles(updatedFiles);
  };

  const getFileDisplay = (file) => {
    if (file.type.startsWith('image/')) {
      return <div><span><img src={URL.createObjectURL(file)} alt={file.name} /></span><p>{file.name}</p></div>;
    } else if (file.name.match(/\.(zip)$/i)) {
      return <div><span><img src='/assets/images/zip.png' alt='' /></span><p>{file.name}</p></div>;
    } else if (file.name.match(/\.(pdf)$/i)) {
        return <div> <span><img src='/assets/images/pdf.png' alt='' /></span> <p>{file.name}</p></div>;
    } else if (file.name.match(/\.(doc|docx)$/i)) {
      return <div> <span><img src='/assets/images/doc.png' alt='' /></span> <p>{file.name}</p></div>;
    } else {
      return <div> <span><img src='/assets/images/document.png'   alt='' /></span> <p>{file.name}</p></div>;
    }
  };

  return (
    <div className='upload-files'>
      <input type="file" multiple onChange={handleFileChange} />
      <div className='inner-div-uploads'>
        {files.map((file, index) => (
          <div key={index} className='single-file-uploaded'>
            {getFileDisplay(file)}
            <button onClick={() => handleDelete(index)}>
            <svg width="25" height="25" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#000'><path d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/></svg>
            </button>
          </div>
        ))}
      </div>
    </div>
    
  );
}

export default FileUpload;
